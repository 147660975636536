<template>
	<div class="pageContainer">
		<a-row class="checkOrder">
			<a-form-model style="position: relative; top: -5px;" layout="inline" ref="ruleForm" :model="formInline" @submit="handleSubmit" @submit.native.prevent>
				<a-form-model-item ref="time" prop="time" style="width: 26em;">
					<a-range-picker v-model="formInline.time" />
				</a-form-model-item>
				<a-form-model-item ref="flowId" prop="flowId" allowClear>
					<a-input v-model="formInline.flowId" allowClear placeholder="请输入订单编号" style="width: 17.5em;" />
				</a-form-model-item>
				<a-form-model-item ref="payState" prop="payState">
					<a-select v-model="formInline.payState" style="width: 11em" placeholder="预约金支付状态" allowClear>
						<a-select-option value="0">
							退款失败
						</a-select-option>
						<a-select-option value="1">
							支付成功
						</a-select-option>
						<a-select-option value="2">
							支付失败
						</a-select-option>
						<a-select-option value="3">
							已退款
						</a-select-option>
						<a-select-option value="4">
							待支付预约金
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item ref="balancePayState" prop="balancePayState">
					<a-select v-model="formInline.balancePayState" style="width: 11em" placeholder="尾款支付状态" allowClear>
						<a-select-option value="1">
							支付成功
						</a-select-option>
						<a-select-option value="2">
							支付失败
						</a-select-option>
						<a-select-option value="4">
							待支付尾款
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item ref="type" prop="type">
					<a-select v-model="formInline.type" style="width: 11em" placeholder="订单状态" allowClear>
						<a-select-option value="0">
							已取消
						</a-select-option>
						<a-select-option value="1">
							预约中
						</a-select-option>
						<a-select-option value="9">
							待检测
						</a-select-option>
						<a-select-option value="3">
							待上传检测报告
						</a-select-option>
						<a-select-option value="2">
							检测中
						</a-select-option>
						<a-select-option value="8">
							待修改
						</a-select-option>
						<a-select-option value="4">
							待确认
						</a-select-option>
						<a-select-option value="5">
							已完成
						</a-select-option>
					</a-select>
				</a-form-model-item>

				<a-form-model-item class="search">
					<a-dropdown-button type="primary" html-type="submit">
						<a-icon type="search" />
						查询
						<a-menu slot="overlay">
							<a-menu-item key="1" @click="reset">重置查询选项</a-menu-item>
						</a-menu>
					</a-dropdown-button>
				</a-form-model-item>
			</a-form-model>

			<a-table
				:columns="columns"
				:loading="tableLoading"
				:data-source="data"
				class="table"
				:pagination="pagination"
				@change="tableChange"
				:expandedRowKeys="expandedRowKeys"
				:defaultExpandAllRows="true"
				:scroll="{ y: tableHeight }"
				rowKey="flowId"
			>
				<a-tag slot="advancePayType" slot-scope="text" class="none">{{ text == 1 ? '支付宝支付' : text == 2 ? '微信支付' : '--' }}</a-tag>
				<a-tag slot="balancePayType" slot-scope="text" class="none">{{ text == 1 ? '支付宝支付' : text == 2 ? '微信支付' : '--' }}</a-tag>
				<a-tag
					class="nonePay"
					slot="advancePayState"
					slot-scope="text"
					:color="text == 0 ? '#f5222d' : text == 1 ? '#52c41a' : text == 2 ? '#f5222d' : text == 3 ? '#13c2c2' : text == 4 ? '#F89900' : ''"
				>
					{{ text == 0 ? '退款失败' : text == 1 ? '支付成功' : text == 2 ? '支付失败' : text == 3 ? '已退款' : text == 4 ? '待支付预约金' : '--' }}
				</a-tag>
				<a-tag
					class="nonePay"
					slot="balancePayState"
					slot-scope="text, record"
					:color="
						text == 0
							? '#f5222d'
							: text == 1
							? '#52c41a'
							: text == 2
							? '#f5222d'
							: text == 3
							? '#13c2c2'
							: text == 4
							? '#F89900'
							: !text && record.advancePayState == 1 && (record.state == 9 || record.state == 8)
							? '#F89900'
							: ''
					"
				>
					{{
						text == 0
							? '退款失败'
							: text == 1
							? '支付成功'
							: text == 2
							? '支付失败'
							: text == 3
							? '已退款'
							: text == 4
							? '待支付尾款'
							: !text && record.advancePayState == 1 && (record.state == 9 || record.state == 8)
							? '待设置'
							: '--'
					}}
				</a-tag>
				<a-table slot="expandedRowRender" class="innerTable" :columns="innerColumns" slot-scope="record" :data-source="record.securityList" :pagination="false" rowKey="flowId">
					<span slot="photoFrontUrl" slot-scope="text, record">
						<img :src="text" class="img" @click="preview(record.photoFrontUrl)" />
						<img :src="record.photoDegreesUrl" class="img" @click="preview(record.photoDegreesUrl)" />
					</span>
					<span slot="checkUserCode" slot-scope="text, record">
						{{ record.checkUserName || '--' }}
					</span>
					<span slot="state">
						{{
							record.state == 0
								? '已取消'
								: record.state == 1
								? '预约中'
								: record.state == 2
								? '检测中'
								: record.state == 3
								? '待上传检测报告'
								: record.state == 4
								? '待确认'
								: record.state == 5
								? '已完成'
								: record.state == 9
								? '待检测'
								: record.state == 8
								? '待设置'
								: '--'
						}}
					</span>
					<span slot="operation" class="table-operation">
						<a v-if="record.checkReportUrl && record.balancePayState == 1 && (record.state == 4 || record.state == 5)" :href="record.checkReportUrl" target="_blank">下载检测报告</a>
						<a v-else>待上传检测报告</a>
					</span>
					<template slot="footer">
						<div class="tableFooter">
							<span class="left">
								<span class="marginR">
									检测总费用：
									<span class="red">￥{{ ((record.advanceAmount + record.balanceAmount) / 1000).toFixed(2) || 0 }}</span>
								</span>
								<span class="marginR">
									预付款：
									<span class="yellow">￥{{ (record.advanceAmount / 1000).toFixed(2) || 0 }}</span>
								</span>
								<span class="marginR">
									尾款：
									<span class="yellow">￥{{ (record.balanceAmount / 1000).toFixed(2) || 0 }}</span>
								</span>
							</span>

							<span class="floatR">
								<a-button class="marginR" type="primary" @click="safeDetail(record)">查看详情</a-button>

								<a-popconfirm v-if="record.advancePayState == 4 && record.state != 0" title="确认取消吗？" ok-text="确认" cancel-text="取消" @confirm="cancelOrder(record)">
									<a class="marginR gray">取消订单</a>
								</a-popconfirm>
								<a-button class="marginR gray" v-if="record.state !== 0 && record.advancePayState == 1 && record.balancePayState !== 1" @click="customerService('customerService')">
									联系客服
								</a-button>

								<a-button class="marginR orange" v-if="record.advancePayState == 4 && record.state != 0" @click="pay(record)">支付预约金</a-button>
								<a-button class="marginR orange" v-if="record.balancePayState == 4 && record.state != 0" @click="pay(record)">支付尾款</a-button>
								<a-button class="marginR orange" v-if="record.state == 4 && record.balancePayState == 1" @click="confirm(record)">确认订单</a-button>
							</span>
						</div>
					</template>
				</a-table>
			</a-table>
		</a-row>
		<Vviewer ref="viewer"></Vviewer>
		<CustomerService :visible="visible" :closeModal="closeModal" :goType="goType" />
		<PayModal :userType="userType" :securityState="securityState" :payData="payData" :payModalVisible="payModalVisible" :tradeId="tradeId" :closePayModal="closePayModal" />
	</div>
</template>
<script>
import Vviewer from '../../components/Vviewer';
import PayModal from '../../components/PayModal';
import CustomerService from './components/CustomerService';
import moment from 'moment';

export default {
	components: {
		Vviewer,
		PayModal,
		CustomerService,
	},
	data() {
		const _this = this;
		return {
			ModalText: '客户联系方式：85206222',
			visible: false,
			userType: 6,
			securityState: 0,
			host: '',
			goType: '',
			expandedRowKeys: [],
			formInline: {
				time: [],
			},

			innerColumns: [
				{
					title: '内容',
					dataIndex: 'photoFrontUrl',
					key: 'photoFrontUrl',
					width: '29%',
					scopedSlots: { customRender: 'photoFrontUrl' },
				},
				{
					title: '类型',
					dataIndex: 'type',
					key: 'type',
					width: '15%',
					customRender(text, record) {
						return text == 0 ? '门头广告牌' : text == 1 ? '户外广告牌' : '--';
					},
				},
				{
					title: '检测时间',
					dataIndex: 'appointmentTime',
					key: 'appointmentTime',
					width: '15%',
					customRender(text) {
						return text ? _this.$moment(text).format('YYYY-MM-DD HH:mm:ss') : '--';
					},
				},
				{
					title: '检测机构',
					dataIndex: 'checkUserCode',
					key: 'checkUserCode',
					width: '15%',
					scopedSlots: { customRender: 'checkUserCode' },
				},
				{
					title: '检测状态',
					dataIndex: 'state',
					key: 'state',
					width: '15%',
					scopedSlots: { customRender: 'state' },
				},
				{
					title: '操作',
					width: '15%',
					key: 'operation',
					scopedSlots: { customRender: 'operation' },
				},
			],

			columns: [
				{
					title: '订单编号',
					dataIndex: 'flowId',
					key: 'flowId',
					width: '28.5%',
					customRender(text, record, index) {
						return index + 1 + '、' + text;
					},
				},
				{
					title: '提交时间',
					dataIndex: 'addTime',
					key: 'addTime',
					width: '15%',
					customRender(text) {
						return _this.$moment(text).format('YYYY-MM-DD HH:mm:ss');
					},
				},
				{
					title: '预约金支付方式',
					dataIndex: 'advancePayType',
					key: 'advancePayType',
					width: '15%',
					scopedSlots: { customRender: 'advancePayType' },
				},
				{
					title: '尾款支付方式',
					dataIndex: 'balancePayType',
					key: 'balancePayType',
					width: '15%',
					scopedSlots: { customRender: 'balancePayType' },
				},

				{
					title: '预约金支付状态',
					dataIndex: 'advancePayState',
					key: 'advancePayState',
					width: '15%',
					scopedSlots: { customRender: 'advancePayState' },
				},
				{
					title: '尾款支付状态',
					dataIndex: 'balancePayState',
					key: 'balancePayState',
					width: '15%',
					scopedSlots: { customRender: 'balancePayState' },
				},
			],
			data: [],
			pagination: {
				total: 0,
				current: 0,
				defaultPageSize: 10,
				showTotal: (total, range) => `当前第 ${range[0]}-${range[1]} 条 共计 ${total} 条`,
				showSizeChanger: true,
				pageSizeOptions: ['5', '10', '15', '20'],
				onShowSizeChange: (current, pageSize) => {
					this.pageSize = pageSize;
				},
			},
			payData: {},
			payModalVisible: false,
			tradeId: null, // 主订单号
			tableLoading: true,
			pageSize: 10,
			userInfo: {},
			tableHeight: 750,
		};
	},
	created() {
		this.host = process.env.VUE_APP_RESOURCES_HOST;
		this.userInfo = this.$store.getters.userInfo;
		this.handleSubmit('placeholder', 0, 10);

		if (document.body.clientWidth <= 1366) {
			this.tableHeight = 390;
		} else if (document.body.clientWidth > 1366 && document.body.clientWidth <= 1440) {
			this.tableHeight = 500;
		} else if (document.body.clientWidth > 1440 && document.body.clientWidth <= 1600) {
			this.tableHeight = 530;
		} else if (document.body.clientWidth > 1600) {
			this.tableHeight = 650;
		}
	},
	methods: {
		customerService(type) {
			this.visible = true;
			this.goType = type;
		},

		closeModal(e) {
			this.visible = false;
		},
		// 确认订单
		confirm(data) {
			this.tableLoading = true;
			const _this = this;
			const params = {
				flowId: data.flowId,
				state: 4,
			};
			_this.$http
				.post('/securitycheckorder/confirmOrder', params)
				.then(res => {
					_this.$message.success('操作成功！');
					_this.handleSubmit('placeholder', this.pagination.current - 1, this.pageSize ? this.pageSize : 10);
				})
				.catch(err => {
					console.log('操作失败', err);
					this.tableLoading = false;
				});
		},
		// 取消订单
		cancelOrder(orderData) {
			const params = {
				flowId: orderData.flowId,
				state: 0,
			};
			let _this = this;
			_this.tableLoading = true;
			_this.$http
				.post('/securitycheckorder/update', params)
				.then(res => {
					_this.$message.success('操作成功！');
					_this.handleSubmit('placeholder', this.pagination.current - 1, this.pageSize ? this.pageSize : 10);
				})
				.catch(err => {
					console.log('取消订单失败', err);
					this.tableLoading = false;
				});
		},
		closePayModal() {
			this.payModalVisible = false;
			this.handleSubmit('placeholder', this.pagination.current - 1, this.pageSize ? this.pageSize : 10);
		},
		pay(e) {
			if (e.balanceAmount && e.advancePayState === 1) {
				this.payData.payAmount = e.balanceAmount / 1000;
				this.securityState = 1;
			} else {
				this.payData.payAmount = e.advanceAmount / 1000;
			}
			this.tradeId = e.flowId;
			this.payModalVisible = true;
		},
		tableChange(e) {
			this.handleSubmit('placeholder', e.current - 1, e.pageSize);
			this.pagination.current = e.current;
		},
		// 订单查询
		handleSubmit(e, pageNum, pageSize) {
			let that = this;
			//查询
			let time = this.formInline.time;
			let params = {
				pageNum: pageNum || pageNum == 0 ? pageNum : 0,
				pageSize: pageSize ? pageSize : that.pageSize,
				userCode: this.userInfo.userCode,
			};
			if (time && time.length > 0) {
				let minAddTime = that.$moment(time[0]).format('YYYY-MM-DD') + 'T00:00:00';
				let maxAddTime = that.$moment(time[1]).format('YYYY-MM-DD') + 'T23:59:59';
				params.minAddTime = minAddTime;
				params.maxAddTime = maxAddTime;
			}
			if (this.formInline.flowId) {
				params.flowId = this.formInline.flowId;
			}
			if (this.formInline.payState) {
				params.advancePayState = this.formInline.payState;
			}
			if (this.formInline.balancePayState) {
				params.balancePayState = this.formInline.balancePayState;
			}
			if (this.formInline.type) {
				params.state = this.formInline.type;
			}
			that.tableLoading = true;
			this.$http
				.post('/securitycheckorder/queryPageList', params)
				.then(res => {
					if (res.rescode == 200) {
						that.tableLoading = false;
						res.data.dataList.map(item => {
							let securityList = {};
							securityList = item.securityCheckVo;
							securityList.checkUserName = item.checkUserName;
							securityList.userName = item.userName;
							return (item.securityList = [securityList]);
						});
						this.data = res.data.dataList;
						let list = [];
						res.data.dataList.map(item => {
							list.push(item.flowId);
						});
						this.expandedRowKeys = list;
						this.pagination.total = res.data.totalCount;
					} else {
						this.$message.warning(res.msg);
					}
				})
				.catch(error => {
					console.log(error);
				})
				.finally(() => {
					that.tableLoading = false;
				});
		},
		reset() {
			this.$refs.ruleForm.resetFields();
			this.handleSubmit('placeholder', 0, this.pageSize);
			this.pagination.current = 0;
		},
		safeDetail(data) {
			this.$router.push({
				name: 'SafeDetail',
				query: { tradeId: data.flowId },
			});
		},
		preview(data) {
			this.$refs.viewer.show([
				{
					thumbnail: data,
					source: data,
				},
			]);
		},
	},
};
</script>
<style lang="less" scoped>
.pageContainer {
	box-sizing: border-box;
	margin-left: 9px;
	margin-top: 9px;
	margin-bottom: 8px;
	height: 98%;
	overflow-y: auto;
	background: #fff;
	.checkOrder {
		padding: 6px;
		height: 100%;
		background: #fff;
	}
	.ant-form {
		// height: 7.3%;
	}
	/deep/.ant-table-row-expand-icon {
		display: none;
	}
	/deep/.ant-table-row-expand-icon-cell {
		border: 0;
	}
	.button {
		margin-top: 3px;
		margin-right: 20px;
		color: #999999;
	}
	.search {
		float: right;
	}
	.none {
		margin-left: -6px;
		border: none;
		background: #edf3ff;
	}
	.nonePay {
		border: none;
		background: #edf3ff;
	}
	.table {
		.innerTable {
			/deep/ .ant-table-row-level-0 {
				background: unset;
			}
		}
		/deep/ .ant-table-row-level-0 {
			background: #edf3ff;
		}
		.img {
			width: 60px;
			height: 40px;
			margin-right: 15px;
		}
		.icon {
			font-size: 25px;
			cursor: pointer; //鼠标变小手
		}
	}
	.marginR {
		margin-right: 20px;
	}
	.orange {
		background-color: #ff9e02;
		color: #fff;
	}
	.gray {
		padding: 5px 15px;
		padding-bottom: 8px;
		background-color: #cccccc;
		color: #fff;
		border-radius: 4px;
		font-size: 14px;
	}
	.tableFooter {
		.left {
			margin-top: 20px;
		}
		.floatR {
			float: right;
		}
	}

	.yellow {
		color: rgb(248, 153, 0);
	}
	.red {
		color: rgb(255, 0, 20);
	}
}
</style>
