<template>
	<!-- :maskClosable="false" 弹窗支持点击阴影关闭 -->
	<a-modal
		class="illegalAdSubModal"
		:footer="false"
		:maskClosable="false"
		:visible="visible"
		:confirm-loading="confirmLoading"
		@ok="handleOk"
		@cancel="handleCancel"
		:width="goType != 'legalConsulting' && goType != 'medical' ? '400px' : '1000px'"
	>
		<a-row class="row" v-if="goType != 'legalConsulting' && goType != 'medical'">
			<img :src="src" />
		</a-row>
		<a-row class="row" v-if="goType == 'customerService'">
			提示：请添加人工客服微信
		</a-row>
		<a-row class="row" v-if="goType == 'customerService'">
			服务时间：周一至周六 09:00-18:00
		</a-row>
		<Examine :examineVisible="examineVisible" :closeExamineModal="closeExamineModal" :goExamineType="goExamineType" />
	</a-modal>
</template>

<script>
import Examine from './Examine';
export default {
	props: ['visible', 'closeModal', 'goType'],
	components: {
		Examine,
	},
	data() {
		return {
			confirmLoading: false,
			src: 'https://resources.xbov.cn/img/hxznggfwpt/WeChat.jpg',
			examineVisible: false,
			goExamineType: '',
		};
	},
	methods: {
		handleCancel() {
			this.closeModal();
		},
		handleOk() {
			this.confirmLoading = true;
			setTimeout(() => {
				this.closeModal();
				this.confirmLoading = false;
				this.$router.push({
					name: 'FinancialManagement',
					query: {},
				});
			}, 2000);
		},
		examine(type) {
			this.examineVisible = true;
			this.goExamineType = type;
		},
		closeExamineModal() {
			this.examineVisible = false;
		},
		// goMedical() {
		//     window.open("https://auth.zwfw.hunan.gov.cn/oauth2/authorize?client_id=sXK6HBx3QwuJqaMXqmx2fQ&response_type=gov&redirect_uri=http://zwfw-new.hunan.gov.cn:80/oauth2-login?backUrl=http://zwfw-new.hunan.gov.cn:80/hnvirtualhall/index.jsp");
		// },
	},
};
</script>

<style lang="less" scoped>
.illegalAdSubModal {
	/deep/ .userPhoneSmsInput {
		width: 78%;
	}
	.row {
		margin-top: 10px;
		font-size: 20px;
		text-align: center;
		img {
			width: 200px;
			height: 200px;
		}
	}
}
</style>
